<template>
  <div>
    <div class="contacts" ref="contacts">
      <ul>
        <li class="contacts-item">
          <div
            class="contacts-item-illustration bg-color-gray-300 text-color-green-500"
          >
            <svg class="icon icon-small phone">
              <use xlink:href="/images/icon-lib.svg#phone" />
            </svg>
          </div>
          <div class="contacts-item-value">
            <a
              href="tel:+55-1142229044"
              class="contacts-item-phone"
            >(11) 4222 9044</a> |
            <a
              href="tel:+55-11999207455"
              class="contacts-item-phone"
            >
            (11) 99920 7455</a>
          </div>
        </li>
        <li class="contacts-item">
          <a href="mailto:contato@mffilm.com.br" class="contacts-item-email">
          <div
            class="contacts-item-illustration bg-color-gray-300 text-color-blue-500"
          >
            <svg class="icon icon-small icon-email-action-unread">
              <use xlink:href="/images/icon-lib.svg#icon-email-action-unread" />
            </svg>
          </div>
          <div class="contacts-item-value">contato@mffilm.com.br</div>
          </a>
        </li>
        <li class="contacts-item">
          <div
            class="contacts-item-illustration bg-color-gray-300 text-color-red-500"
          >
            <svg class="icon icon-small icon-style-two-pin-marker">
              <use xlink:href="/images/icon-lib.svg#icon-style-two-pin-marker" />
            </svg>
          </div>
          <div class="contacts-item-value">
            Av Humberto de Alencar castelo branco, 1300 São Bernardo do Campo -
            São Paulo.
          </div>
        </li>
      </ul>
    </div>

    <router-link to @click.native="openWhatsApp" class="whatsapp">
      <div class="whatsapp-illustration"><img src="/images/logo-whatsapp-symbol.svg" width="34" height="34" alt=""> </div>
      <div>Cotação via WhatsApp</div>
    </router-link>

  </div>
</template>

<script>
import { sendEventAnalytics } from "@/mixin/send-event-analytics.js";


export default {    
  mixins: [ sendEventAnalytics],

  methods: {
    openWhatsApp: function () {

      this.sendEventAnalytics(
        'cotacao-whatsapp',
        'botao', 
        'cotacao whatsapp enviado',
        'cotacao whatsapp'
      );

      window.open('https://wa.me/5511999207455?lang=pt_br', '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
.contacts, .whatsapp {
  @media (min-width: $screenLarge) {
    width: 415px;
  }
  margin:2rem auto;
}

.contacts-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;

  @media (min-width: $screenLarge) {
    margin-bottom: 2rem;
  }
  a{    
    color: $primary-text-color;
  }

}

.contacts-item-email {
  display: flex;
  align-items: center;
}


.contacts-item-illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  border-radius: 50%;
}

.contacts-item-value {
  text-align: left;
  font-size: $BodyText2;
  font-weight: 300;

  @media (min-width: $screenLarge) {
    font-size: $BodyText;
  }
}

.whatsapp {
  display: block;
  padding: 1.5rem;
  font-size: $BodyText2;
  font-weight: 300;
  background-color: lighten($gray-300, 1.5%);
  color: $primary-text-color;
}
.whatsapp-illustration {
  margin-bottom: .5rem;
}

</style>